import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { fetchData } from './data/fetchData.jsx';
import appContext from './context/appContext.jsx';
import './App.css';
import MainLayout from './layouts/MainLayout';
import Home from './components/home/Home.jsx';
import AllCategories from './components/menu/AllCategories.jsx';
import Products from './components/menu/Products.jsx';
import Cart from './components/cart/Cart.jsx';
import Favorite from './components/favorite/Favorite.jsx';
import Loading from './UI/Loading.jsx';
import Contacts from './components/info/Contacts.jsx';
import Delivery from './components/info/Delivery.jsx';
import Rules from './components/info/Rules.jsx';
import Privacy from './components/info/Privacy.jsx';
import MyOrders from './components/myOrders/MyOrders.jsx';
import Liqpay from './components/payment/Liqpay.jsx';

const tg = window.Telegram.WebApp;
tg.expand();

const config = {
    initUrl: process.env.REACT_APP_INIT_URL,
    myOrdersUrl: process.env.REACT_APP_MYORDERS_URL,
    newOrderUrl: process.env.REACT_APP_NEWORDER_URL,
    favoriteUrl: process.env.REACT_APP_FAVORITE_URL,
    getZoneUrl: process.env.REACT_APP_GETZONE_URL,
};

function App() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [cart, setCart] = useState([]);
    const [repeatOrderParams, setRepeatOrderParams] = useState(null);

    const fetchProducts = useCallback(async () => {
        try {
            const fetchedData = await fetchData(config.initUrl, 'POST', { initData: tg.initData });
            setData(fetchedData);
        } catch (err) {
            setError('Помилка при завантаженні даних.');
            console.error('Error fetching data:', err);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProducts();
    }, [fetchProducts]);

    const { products = [], categories = [], cities = [], locations = [], related = [], customer = {} } = useMemo(() => data || {}, [data]);

    const updateFavorite = async (id, isFavorite) => {
        try {
            await fetchData(config.favoriteUrl, 'POST', { initData: tg.initData, id, isFavorite });
        } catch (err) {
            setError('Помилка при завантаженні даних.');
            console.error('Error fetching data:', err);
        }
    };

    const setIsFavorite = useCallback((id) => {
        setData((prevData) => {
            const updatedProducts = prevData.products.map((item) => {
                if (item.id === id) {
                    const newFavoriteStatus = !item.isFavorite;
                    updateFavorite(id, newFavoriteStatus);
                    return { ...item, isFavorite: newFavoriteStatus };
                }
                return item;
            });

            return { ...prevData, products: updatedProducts };
        });
    }, []);

    const contextValue = useMemo(
        () => ({
            categories,
            products,
            setIsFavorite,
            cart,
            setCart,
            cities,
            locations,
            related,
            customer,
            identified: customer.identified,
            repeatOrderParams,
            setRepeatOrderParams,
        }),
        [categories, products, cart, setIsFavorite, cities, locations, related, customer, repeatOrderParams]
    );

    if (loading) return <Loading />;
    if (error)
        return (
            <div className="errMsg">
                <span>{error}</span>
            </div>
        );

    return (
        <BrowserRouter>
            <main className="App">
                <appContext.Provider value={contextValue}>
                    <Routes>
                        <Route path="/" element={<MainLayout />}>
                            <Route index element={<Home />} />
                            <Route path="menu" element={<AllCategories />} />
                            <Route path="menu/:slug" element={<Products />} />
                            <Route
                                path="cart"
                                element={<Cart newOrderUrl={config.newOrderUrl} getZoneUrl={config.getZoneUrl} initData={tg.initData} />}
                            />
                            <Route path="favorite" element={<Favorite products={products} />} />
                            <Route path="contacts" element={<Contacts contacts={true} />} />
                            <Route path="delivery-and-pay" element={<Delivery />} />
                            <Route path="rules" element={<Rules />} />
                            <Route path="privacy-policy" element={<Privacy />} />
                            <Route
                                path="orders"
                                element={<MyOrders myOrdersUrl={config.myOrdersUrl} paymentUrl={config.newOrderUrl} initData={tg.initData} />}
                            />
                            <Route path="payment" element={<Liqpay />} />
                            <Route path="*" element={<h1>Не знайдено</h1>} />
                        </Route>
                    </Routes>
                </appContext.Provider>
            </main>
        </BrowserRouter>
    );
}

export default App;
