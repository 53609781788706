import React, { useEffect, useState, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import uk from 'date-fns/locale/uk';
import 'react-datepicker/dist/react-datepicker.css';
import style from './DateTimePicker.module.css';
import { fetchData } from '../data/fetchData';

registerLocale('uk', uk);
setDefaultLocale('uk');

const DateTimePicker = React.memo(({ formData, handleFormChange, getZoneUrl }) => {
    const [curDate, setCurDate] = useState(new Date(formData.deliveryDate || Date.now()));
    const [isOpen, setIsOpen] = useState(false);
    const [currentNearestTime, setNearestTime] = useState();

    useEffect(() => {
        setCurDate(new Date(formData.deliveryDate));

        const address = {
            city: formData.address.city,
            street: formData.address.street,
            house: formData.address.house,
        };
        const fetchNearestTime = async () => {
            const adjustedTime = await getAdjustedTime(formData.deliveryOption, address, getZoneUrl);
            setNearestTime(adjustedTime);
        };

        fetchNearestTime();
    }, [formData.deliveryDate, formData.deliveryOption, formData.address, getZoneUrl]);

    const generateTimeSlots = useMemo(() => generateDeliveryTimeSlots(curDate, formData.deliveryOption), [curDate, formData.deliveryOption]);

    useEffect(() => {
        if (formData.deliveryTiming === 'timed' && !formData.timeInterval && generateTimeSlots.length > 0) {
            handleFormChange({ target: { name: 'timeInterval', value: generateTimeSlots[0] } });
        }
    }, [formData.deliveryTiming, formData.timeInterval, generateTimeSlots, handleFormChange]);

    const handleDateChange = (date) => {
        setCurDate(date);
        handleFormChange({ target: { name: 'deliveryDate', value: date.toISOString().split('T')[0] } });
        handleFormChange({ target: { name: 'deliveryTiming', value: 'timed' } });
        setIsOpen(false);
    };

    const formatDateDisplay = (date) => {
        const today = new Date();
        const tomorrow = new Date(Date.now() + 86400000);

        if (date.toDateString() === today.toDateString()) return 'Сьогодні';
        if (date.toDateString() === tomorrow.toDateString()) return 'Завтра';
        return date.toLocaleDateString('uk-UA');
    };

    return (
        <div className={style.container}>
            <div className={style.dateContainer}>
                <label htmlFor="displayDate">Дата:</label>
                <div className={style.displayDateWrapper}>
                    <input
                        type="text"
                        id="displayDate"
                        value={formatDateDisplay(curDate)}
                        readOnly
                        onClick={() => setIsOpen((prev) => !prev)}
                        className={style.deliveryDate}
                    />
                    {isOpen && (
                        <DatePicker
                            selected={curDate}
                            onChange={handleDateChange}
                            inline
                            minDate={new Date()}
                            locale="uk"
                            onClickOutside={() => setIsOpen(false)}
                            excludeDates={[new Date(2024, 11, 31), new Date(2025, 0, 1)]}
                        />
                    )}
                </div>
            </div>
            <DeliveryTiming
                deliveryTiming={formData.deliveryTiming}
                setDeliveryTiming={(value) => {
                    handleFormChange({ target: { name: 'deliveryTiming', value } });
                    if (value === 'delivery' && !formData.timeInterval) {
                        handleFormChange({ target: { name: 'timeInterval', value: generateTimeSlots[0] } });
                    } else {
                    }
                }}
                currentNearestTime={currentNearestTime}
                curDate={curDate}
            />
            {formData.deliveryTiming === 'timed' && (
                <TimeSlotSelector
                    generateTimeSlots={generateTimeSlots}
                    handleFormChange={handleFormChange}
                    currentInterval={formData.timeInterval || generateTimeSlots[0]}
                />
            )}
        </div>
    );
});

const generateDeliveryTimeSlots = (curDate, deliveryOption) => {
    const slots = [];
    const now = new Date();
    const isNewYearEve = curDate.toDateString() === new Date(2024, 11, 31).toDateString();
    const startHour = isNewYearEve ? 9 : 11;
    const startMinutes = isNewYearEve ? 0 : deliveryOption === 'delivery' ? 30 : 0;
    const endHour = isNewYearEve ? 9 : deliveryOption === 'delivery' ? 22 : 21;
    const nowPlus = new Date(now.getTime() + (deliveryOption === 'delivery' ? 2 * 3600 * 1000 : 45 * 60 * 1000));

    for (let hour = startHour; hour <= endHour; hour++) {
        const initialMinutes = hour === startHour ? startMinutes : 0;
        const maxMinutes = hour === 21 && deliveryOption === 'pickup' ? 30 : 60; // 21:30 для без самовывоз

        for (let minutes = initialMinutes; minutes < maxMinutes; minutes += 30) {
            const startTime = new Date(curDate);
            startTime.setHours(hour, minutes, 0, 0);

            if (curDate.toDateString() === now.toDateString() && startTime < nowPlus) continue;

            const endTime = new Date(startTime);
            endTime.setMinutes(endTime.getMinutes() + 30);
            slots.push(`${startTime.toTimeString().slice(0, 5)}-${endTime.toTimeString().slice(0, 5)}`);
        }
    }
    return slots;
};

const DeliveryTiming = ({ deliveryTiming, setDeliveryTiming, currentNearestTime, curDate }) => {
    const now = new Date();
    const currentHours = now.getHours();
    const isNightTime = currentHours >= 21 || currentHours < 10;

    return (
        <div className={style.deliveryOptionContainer}>
            {curDate.toDateString() === new Date().toDateString() && !isNightTime && (
                <div className={style.switchOption}>
                    <input
                        type="radio"
                        id="nearest"
                        name="deliveryTiming"
                        value="nearest"
                        checked={deliveryTiming === 'nearest'}
                        onChange={() => setDeliveryTiming('nearest')}
                        className={style.toggleOption}
                    />
                    <label htmlFor="nearest" className={style.toggleOptionLabel}>
                        На найближчий час до {currentNearestTime}
                    </label>
                </div>
            )}
            <div className={style.switchOption}>
                <input
                    type="radio"
                    id="timed"
                    name="deliveryTiming"
                    value="timed"
                    checked={deliveryTiming === 'timed'}
                    onChange={() => setDeliveryTiming('timed')}
                    className={style.toggleOption}
                />
                <label htmlFor="timed" className={style.toggleOptionLabel}>
                    На час
                </label>
            </div>
        </div>
    );
};

const TimeSlotSelector = ({ generateTimeSlots, handleFormChange, currentInterval }) => (
    <div className={style.timeContainer}>
        <select name="timeInterval" className={style.timeSelect} value={currentInterval || ''} onChange={handleFormChange}>
            <option value="" disabled>
                Оберіть час
            </option>
            {generateTimeSlots.map((time) => (
                <option key={time} value={time}>
                    {time}
                </option>
            ))}
        </select>
    </div>
);

const getAdjustedTime = async (deliveryOption, address, getZoneUrl) => {
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    let minutes = 120;

    if (deliveryOption === 'pickup') {
        minutes = 45;
        if (currentHours === 10 && currentMinutes <= 30) {
            minutes = 75;
        }
    } else {
        // 1 зона + 80 хв, 2 зона + 110 хв, 3-4 зони + 140 хв
        if (address.city && address.street && address.house) {
            try {
                const responseData = await fetchData(getZoneUrl, 'POST', address);
                if (responseData.success) {
                    if (responseData.data) {
                        const zone = responseData.data.zone;
                        if (zone === 1) minutes = 80;
                        else if (zone === 2) minutes = 110;
                        else if (zone === 3 || zone === 4) minutes = 140;
                    }
                }
            } catch (error) {}
        }
    }

    const roundedMinutes = Math.ceil((now.getMinutes() + minutes) / 5) * 5;

    now.setMinutes(roundedMinutes);
    return now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export default DateTimePicker;
